<!--
 * @Author: FengHang
 * @LastEditors: FengHang
 * @Date: 2022-06-29 16:14:29
 * @LastEditTime: 2022-06-30 10:43:43
-->
<template>
	<div class="activityMyAdd">
		<div class="activityMyAdd-content">
			<div class="common-row">
				<div class="common-row-item">
					<span class="common-row-label">基地名称</span>
					<el-input class="common-row-ipt" readonly="readonly" placeholder="请输入" v-model="activityMyAdd.name" />
				</div>
			</div>
			<div class="common-row">
				<div class="common-row-item">
					<span class="common-row-label">组织名称</span>
					<el-input readonly="readonly" class="common-row-ipt" placeholder="请输入"  v-model="activityMyAdd.organization" />
					<!-- <el-select class="common-row-select" v-model="activityMyAdd.organizationId" placeholder="请选择">
						<el-option v-for="item in originList" :key="item.value" :label="item.label" :value="item.value">
						</el-option>
					</el-select> -->
				</div>
			</div>
			<div class="activityMyAdd-editor">
				<div class="activityMyAdd-editor-title">
					基地详情
				</div>
				<quill-editor   class="handle_form_editor"  :intEditor.sync="activityMyAdd.details" 
					:enableWrite="enableWrite">
				</quill-editor>
			</div>
			<div class="common-row">
				<div class="common-row-item">
					<span class="common-row-label">联系人</span>
					<el-input class="common-row-ipt" placeholder="请输入联系人姓名"  v-model="activityMyAdd.contactPerson" />
				</div>
			</div>
			<div class="activityMyAdd-btn" >
				<el-button v-preventReClick v-if="activityMyAdd.auditStatus==2" @click="submit" type="primary">重新提交</el-button>
				<el-button v-preventReClick v-else @click="submit" type="primary">保存</el-button>
			</div>
		</div>
	</div>
</template>

<script>
	import quillEditor from "@/components/quillEditor.vue";
	import {
		addMyBase,
		detailMyBase,
		editMyBase,
        coConstructionBaseEdit
	} from "@/api/activity";
	import {
		getDepartmentList,
	} from "@/api/system";
	import storage from 'store';
	export default {
		components: {
			quillEditor
		},
		data() {
			return {
				activityMyAdd: {
					name: "",
					organization: "",
					organizationId: "",
					details: "",
					state: 1,

				},
				originList: [],
				enableWrite:false
			};
		},
		mounted() {
			// this.getOriginList()
			// let usrInfo = storage.get('ACCESS_Info')
			// this.activityMyAdd.organization = usrInfo.orgShortName
			// this.activityMyAdd.organizationId = usrInfo.orgId
			this.baseId = this.$route.query.baseId
			this.getMyBaseDetail()
		},
		methods: {
			getMyBaseDetail() {
				detailMyBase(this.baseId, {
					id: this.baseId
				}).then(res => {
					if (res.code == 0) {
						this.activityMyAdd = res.data
					}
				})
			},
			getOriginList() {
				getDepartmentList({
					pageIndex: 1,
					pageSize: 999,
					type: "DEPT"
				}).then((res) => {
					if (res.code == 0) {
						this.originList = res.data.map(item => {
							return {
								label: item.name,
								value: item.id
							}
						})
					}
				})
			},
			submit() {
				let addData = JSON.parse(JSON.stringify(this.activityMyAdd))
				console.log("submit", addData)
				if (!addData.contactPerson) {
					this.$message.error("请填写联系人~")
					return
				}
				coConstructionBaseEdit(this.baseId,{contactPerson:addData.contactPerson}).then((res) => {
					if(res.code == 0) {
						this.$message.success("保存成功~")
						this.$router.go(-1)
					}
				})
			},
		}
	};
</script>

<style lang="scss" scoped>
	/deep/.el-input__inner {
		height: 36px;
		border-radius: 0px 2px 2px 0px;
		border: 1px solid #EDF1F7;
		border-left: 0;
	}

	.activityMyAdd {
		.activityMyAdd-content {
			padding: 20px 20px;

			.common-row {
				margin-bottom: 20px;

				.common-row-ipt {
					flex: 1;
				}

				.common-row-select {
					width: 476px;
				}
			}

			.activityMyAdd-editor {
				display: flex;
				flex-direction: column;
				margin-bottom:20px;
				.activityMyAdd-editor-title {
					background: #F6F7F9;
					border: 1px solid #EDF1F7;
					border-radius: 2px 2px 0px 0px;
					line-height: 36px;
					padding: 0 22px;
					font-size: 14px;
					font-family: PingFang SC;
					font-weight: 400;
					color: #606266;
				}
			}

			.activityMyAdd-btn {
				margin-top: 20px;
			}

		}
	}
</style>
